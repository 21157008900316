import React, { useState, useEffect } from "react"
import axios from "axios"

import SEO from "../SEO"
import Heading from "../Layout/heading"
import Content from "../Layout/content"
import Loader from "../Loader"
import Message from "../Message"

import "./page.scss"

const pagesAPI = process.env.API_PAGES

const Page = props => {
  const [page, setPage] = useState({})
  const [message, setMessage] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    axios
      .get(`${pagesAPI}/${props.id}`)
      .then(response => {
        setPage(response.data)
        setIsLoading(false)
      })
      .catch(error => {
        setMessage({
          type: "danger",
          text: error.response.data.message,
        })
        setIsLoading(false)
      })
  }, [props.id])

  if (isLoading === true) {
    return <Loader />
  } else if (message) {
    return (
      <>
        <Message type={message.type} message={message.text} />
      </>
    )
  } else {
    return (
      <>
        <SEO title={page.title} description={page.description} />
        <Heading title={page.title} />
        <Content content={page.content} />
      </>
    )
  }
}

export default Page
