import React from "react"

import Layout from "../components/Layout"
import Page from "../components/Layout/page"

const PrivacyPolicy = props => (
  <Layout location={props.location}>
    <Page id="15" />
  </Layout>
)

export default PrivacyPolicy
